import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import Layout from "../../layouts/default"
import { useColorMode } from "theme-ui"

import Box from "../../components/box"
import Breadcrumb from "../../components/breadcrumb"
import Paragraph from "../../components/paragraph"
import Grid from "../../components/grid"
import Heading from "../../components/heading"
import Icon from "../../components/icon"
import { GatsbyImage } from "gatsby-plugin-image"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"
import Text from "../../components/text"

import logoKomm from "../../images/partner-logos/pro-komm.svg"
import logoKommLight from "../../images/partner-logos/pro-komm-light.svg"

const VideoThumbnail = ({ to, key, title, src }) => {
  return (
    <Link to={to} key={key}>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          cursor: "pointer",
          ":hover .video-play-button, :focus .video-play-button": {
            bg: "black",
            color: "white",
          },
          ":hover .video-heading, :focus .video-heading": {
            textDecoration: "underline",
          },
        }}
      >
        <Stack space={3}>
          <Box sx={{ width: "100%", position: "relative" }}>
            <GatsbyImage image={src} alt={`Titelbild Video ${title}`} />
            <Box
              className="video-play-button"
              sx={{
                width: [12, 12, 16],
                height: [12, 12, 16],
                borderRadius: "100%",
                bg: "primary",
                color: "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Icon icon="play" size={[6, 6, 8]} />
            </Box>
          </Box>
          <Heading as="h2" className="video-heading " level={5}>
            {title}
          </Heading>
        </Stack>
      </Box>
    </Link>
  )
}

const GebaerdenSprache = () => {
  const [colorMode] = useColorMode()
  const data = useStaticQuery(graphql`
    query GebaerdenSpracheIndexQuery {
      turmzimmer: file(relativePath: { eq: "sprachen/turmzimmer.jpg" }) {
        ...largeImage
      }
      aussen: file(relativePath: { eq: "sprachen/aussen.jpg" }) {
        ...largeImage
      }
      mediaguide: file(relativePath: { eq: "leichte-sprache/mediaguide.jpg" }) {
        ...largeImage
      }
      garten: file(relativePath: { eq: "sprachen/garten.jpg" }) {
        ...largeImage
      }
      besuch: file(relativePath: { eq: "sprachen/ihr-besuch.jpg" }) {
        ...largeImage
      }
      ogImage: file(relativePath: { eq: "og_images/leichte-sprache.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Layout backdrop="aussen">
      <Seo
        title="Der Hölderlinturm in Gebärdensprache"
        description="In diesen Videos in Gebärdensprache erfahren Sie mehr über den Dichter Friedrich Hölderlin, das Museum Hölderlinturm und unsere Angebote in Gebärdensprache."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Gebärdensprache",
                link: "/gebaerdensprache",
              },
            ]}
          />
          <PageTitle>Der Hölderlinturm in Gebärdensprache</PageTitle>

          <Paragraph hyphens="no" dropcap={true}>
            In diesen Videos in Gebärdensprache erfahren Sie mehr über den
            Dichter Friedrich Hölderlin, das Museum Hölderlinturm und unsere
            Angebote in Gebärdensprache.
          </Paragraph>
          <Grid columns={[1, 2]}>
            <VideoThumbnail
              src={data.aussen.childImageSharp.gatsbyImageData}
              title="Über den Hölderlinturm"
              to="/gebaerdensprache/ueber-den-hoelderlinturm"
              alt="Eine Außenansicht vom Museum Hölderlinturm. Das kleine gelbe Haus mit dem Spitzturm liegt direkt am Neckar."
            />
            <VideoThumbnail
              src={data.turmzimmer.childImageSharp.gatsbyImageData}
              title="Die Dauerausstellung im Hölderlinturm"
              to="/gebaerdensprache/dauerausstellung"
              alt="Hölderlins Turmzimmer in der Dauerausstellung: Der Raum hat drei Fenster. In der Mitte steht ein Schreibtisch. An der Wand ist Hölderlins Gedicht Aussicht zu lesen."
            />
            <VideoThumbnail
              src={data.mediaguide.childImageSharp.gatsbyImageData}
              title="Media-Guide mit Inhalten in Gebärdensprache"
              to="/gebaerdensprache/mediaguide"
              alt="Eine Besucherin wählt auf dem Media-Guide-Gerät den Rundgang in Deutscher Gebärdensprache aus. "
            />
            <VideoThumbnail
              src={data.garten.childImageSharp.gatsbyImageData}
              title="Der Museumsgarten"
              to="/gebaerdensprache/garten"
              alt="Der Museumsgarten. Eine große Steintreppe führt hinunter zum Neckar."
            />
            <VideoThumbnail
              src={data.besuch.childImageSharp.gatsbyImageData}
              title="So kommt man zum Hölderlinturm"
              to="/gebaerdensprache/wegbeschreibungen"
              alt="Der Museumsvorplatz mit der Eingangstür zum Museum."
            />
          </Grid>
        </Stack>

        <Stack>
          <Text size={3} sans>
            Gefördert von
          </Text>
          <Box
            as="img"
            src={colorMode === "light" ? logoKommLight : logoKomm}
            sx={{
              width: "auto",
              height: "120px",
            }}
            alt="Logo Prokomo"
          />
        </Stack>
      </Stack>
    </Layout>
  )
}

export default GebaerdenSprache
